// import {
//     debounce,
//     throttle
// } from "./scripts/__event-utilities";
// import {
//     gsap
// } from "gsap";
// import {
//     ScrollTrigger
// } from "gsap/ScrollTrigger";
import {
    slick
} from "slick-carousel";
// import Iconify from '@iconify/iconify';



// AOS Animations
//------------------------------------------------------------------------------------------------------------------------------------------------

import AOS from 'aos';

jQuery(document).ready(() => {
    AOS.init({
        offset: 100,
        delay: 75,
        duration: 750,
        once: true,
    });

    let $carousel = $('.js-image-carousel');

    if ( $carousel.length ) {
        const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.04 128.22"><path d="M111.04 0v128.22L0 64.11 111.04 0Z" fill="currentcolor"/></svg></button>`;
        const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.04 128.22"><path d="M0 128.22V0l111.04 64.11L0 128.21Z" fill="currentcolor"/></svg></button>`;

        $carousel.slick({
            autoplay: false,
            arrows: true,
            dots: true,
            prevArrow: prevArrowHTML,
            nextArrow: nextArrowHTML,
            infinite: true,
            adaptiveHeight: false,
            draggable: true,
            speed: 400,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false
                    }   
                }
            ]
        });
    }
});
